import React, { useState } from "react";
import { Link } from "react-router-dom";

function CardItem(props) {
  const words = props.description.split("$").filter((word) => word);

  const [currentImage, setCurrentImage] = useState(0);

  const handleClick = () => {
    setCurrentImage((oldImage) => {
      if (oldImage === props.src.length - 1) {
        return 0;
      } else {
        return oldImage + 1;
      }
    });
  };

  return (
    <>
      <li className="cards__item">
        <div className="cards__item__link" to={props.path}>
          <figure className="cards__item__pic-wrap" data-category={props.label}>
            <img
              src={props.src[currentImage]}
              alt="Services photos"
              onClick={handleClick}
              className="cards__item__img"
            />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text">{props.text}</h5>
          </div>
          <div className="cards__item__info">
            <ul>
              {words.map((word, index) => (
                <li className="cards__item__desc" key={index}>
                  {word}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </li>
    </>
  );
}

export default CardItem;
