import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer-container">
        <small className="footer-text">
          OPTIMUM PROCESSING d.o.o, Dragonjska 18, 31000 Osijek, Croatia
        </small>
        <small className="footer-text">
          e-mail: igor.pavicic@opt-process.com
        </small>
      </div>
    </>
  );
}

export default Footer;
