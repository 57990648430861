import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";
import { Link } from "react-router-dom";

function Cards() {
  return (
    <div className="cards">
      <h1 id="services">SERVICES</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={[
                "/images/R&D.png",
                "/images/R&D1.png",
                "/images/R&D2.png",
                "/images/R&D3.png",
                "/images/R&D4.png",
                "/images/R&D5.png",
                "/images/R&D6.png",
                "/images/R&D7.png",
              ]}
              text="R&D"
              // label="R&D"
              path=""
              description="Analog Electronics$Digital Electronics$MCU Programming$PCB$Mechanics$
              Housings$Wiring$Product Integration$Packaging"
            />
            <CardItem
              src={["/images/R&D4.png"]}
              text="Optimisations"
              // label="Optimisations"
              path=""
              description="Design For Manufactureability$Design For Test$Design For Assembly$
              LVD/EMC Compliance"
            />
            <CardItem
              src={["/images/machining.jpeg", "/images/machining2.jpeg"]}
              text="Machining"
              // label="Machining"
              path=""
              description="CNC Milling 3-axis/5-axis$CNC Turning$Laser Cutting$Stamping$CNC Bending$
              Grinding$Heat Treatment$Anodizing And Sealing$Powder Coating$Casting$Injection Molding"
            />
          </ul>

          <ul className="cards__items">
            <CardItem
              src={["/images/wood-machining.jpeg"]}
              text="Wood machining and finishing"
              // label="Wood_machining"
              path="/about"
              description="CNC Wood Machining$Wood Impregnation$Wood Finishing"
            />
            <CardItem
              src={["/images/3dprint.jpeg"]}
              text="3D Printing"
              // label="3D_printing"
              path=""
              description="Filament Printing Up To 350x350x400mm"
            />
          </ul>

          <ul className="cards__items">
            <CardItem
              src={["/images/PCBPCBA.png", "/images/PCBPCBA2.png"]}
              text="PCB/PCBA service"
              // label="PCB/PCBA"
              path=""
              description="1/2/4 Layer PCB Design$Copper Thickness Up To 120um$Gold/Silver/Rhodium/Tin Plating$
              PCB Manufacturing$PCBA Service$Electronic Component Sourcing$End-Of-Line Testing (BARCO ACCU MATCH,
                 MANIA BARCO SPEEDY 580)$MOQ 1pc"
            />
            <CardItem
              src={["/images/WIRING.png", "/images/WIRING2.png"]}
              text="Wiring"
              // label="Wiring"
              path=""
              description="Customized Wiring Assembly$Connector Sourcing$Customized Connector Design And Manufacturing"
            />
            <CardItem
              src={["/images/FINALPRODUCTASSEMBLY.png"]}
              text="Final product Assembly"
              // label="Final product Assembly"
              path=""
              description="Final Assembly Of Mechanics, Electronics And Wiring$Finished Product Ready To Be Tested Before Packaging$
              Customized Connector Design And Manufacturing"
            />
          </ul>

          <ul className="cards__items">
            <CardItem
              src={["/images/TEST1.png"]}
              text="Testing"
              // label="Testing"
              path=""
              description="Sub-Assembly Testing$Final Product Testing$Cycle Testing$Heat Soak$Vibration Testing$Customized Test-Jig Development"
            />
            <CardItem
              src={[
                "/images/LASERENGRAWING.png",
                "/images/LASERENGRAWING2.png",
              ]}
              text="Laser Engraving / Cutting"
              // label="Laser"
              path=""
              description="20W Fiber Laser Engraving$Round Object Engraving$40W CO2 Laser Cutting"
            />
            <CardItem
              src={[
                "/images/PACKAGING.png",
                "/images/PACKAGING1.png",
                "/images/PACKAGING2.png",
              ]}
              text="Packaging solutions"
              // label="Packaging"
              path=""
              description="Packaging Development In 3D CAD$Final Packaging Manufacturing And Material Sourcing"
            />
          </ul>
        </div>
      </div>
      <h1>BRAND DEVELOPMENT</h1>
      <div className="brand-about">
        <img
          src="/images/silence-black.png"
          alt="Silence logo"
          className="silence-logo"
        ></img>
        <p>
          Visit our Silence website on{" "}
          <Link className="link-silence" to="https://silenceaudio.eu">
            silenceaudio.eu
          </Link>
        </p>
      </div>
      <h1 id="about">ABOUT US</h1>
      <div className="home-about">
        <p>
          Optimum Processing d.o.o. is a product manufacturing company based in
          EU, offering a One-Stop solution for a complete OEM/ODM service from
          Product R&D, DFM/DFT/DFA optimization of existing products, PCB/PCBA
          service, Electronic component and connector sourcing, Wiring, Final
          product assembly, End Of Line test and Packaging Solutions.
        </p>
        <p>
          Company is specialized in Hi-End audio and Industrial Automation
          electronics with many years of experience with leading brands in the
          industry.
        </p>
      </div>
    </div>
  );
}

export default Cards;
